import {staff} from './staff';
import {partner} from './partner';

const authConfigs = {
    staff,
    partner
}
const envs = {
    env: "fn1",
};
export {
    authConfigs, envs
}
